import React, {useRef, useEffect} from 'react';
import * as classes from './LogoLoader.module.css';

import LogoLoaderGIF from '../../../assets/images/logos/Logo-WO-Loader.gif';

const LogoLoader = () => {

	/*const AnimRef = useRef(null)

	const restartAnimation = (spans) => {
		setTimeout(function(){
			for(let i=0; i<spans.length; i++){
				spans[i].style.animation = 'none';
				setTimeout(function(){
					spans[i].style.animation = '';
				},300)
			}
			restartAnimation(spans)
		},2700)
	}

	useEffect(()=>{
		let spans = AnimRef.current.children;
		restartAnimation(spans)
	},[])*/

	return (
		<div className={classes.LogoLoader} id="LogoLoaderID">
			<div>
				{/*ref={AnimRef}>*/}
				{/*<span className={classes.weboasis}>W</span>
				<span className={classes.weboasis}>e</span>
				<span className={classes.weboasis}>b</span>
				<span className={classes.weboasis}>o</span>
				<span className={classes.weboasis}>a</span>
				<span className={classes.weboasis}>s</span>
				<span className={classes.weboasis}>i</span>
				<span className={classes.weboasis}>s</span>*/}
				<img src={LogoLoaderGIF} alt="Weboasis logo animation" />
			</div>
		</div>
	);
}

export default LogoLoader;