import React, { useEffect, useState } from "react";
import * as classes from "./Header.module.css";

import WO_LOGO from "../../assets/images/logos/new_logo_2.png";
import WO_LOGO_SMALL from "../../assets/images/logos/new_logo_small_3.png";

import { scroller } from "react-scroll";
import { navigate } from "gatsby";

import Instagram from "../../assets/images/others/instagram.png";
import Email from "../../assets/images/others/email.png";
import LogoLoader from "./LogoLoader/LogoLoader";

import Content from "../../../content.json";

const Header = (props) => {
  const [Open, IsOpen] = useState(false);
  const [head, SetHead] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [mobile, setMobile] = useState(false);
  const isBrowser = () => typeof window !== "undefined";
  /*let logo = "desktop";
    if(isBrowser()){
        if(window.innerWidth < 768){
            logo = "mobile";
        }
    }*/

  const ChangeState = () => {
    IsOpen(!Open);
  };

  useEffect(() => {
    if (isBrowser()) {
      const addHeaderBackground = () => {
        if (window.scrollY > 50) {
          SetHead(true);
          document.getElementById("ImageId").style.marginTop = "15px";
          document.getElementById("MenuId").style.marginTop = "10px";
          document.getElementById("HeaderId").style.paddingBottom = "15px";
          if (window.innerWidth < 769) {
            setMobile(true)
            document.getElementById("menuItemsId").style.paddingTop = "60px";
          } else {
            document.getElementById("menuItemsId").style.paddingTop = "10px";
          }
        } else {
          SetHead(false);
          document.getElementById("ImageId").style.marginTop = "30px";
          document.getElementById("MenuId").style.marginTop = "25px";
          document.getElementById("HeaderId").style.paddingBottom = "0px";
          if (window.innerWidth < 769) {
            setMobile(true)
            document.getElementById("menuItemsId").style.paddingTop = "60px";
          } else {
            document.getElementById("menuItemsId").style.paddingTop = "20px";
          }
        }
      };
      window.addEventListener("scroll", () => {
        addHeaderBackground();
        IsOpen(false);
      });

      const removeLoader = () => {
        if (document.getElementById("LogoLoaderID")) {
          document.getElementById("LogoLoaderID").style.opacity = "0";
        }
        setShowLoader(false);
      };
      
      addHeaderBackground();
      if (document.readyState === "complete") {
        removeLoader();
      } else {
        window.addEventListener("load", function () {
          removeLoader();
        });
      }
    }
  }, []);

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (window.location.pathname !== "/") {
      await navigate("/");
      setTimeout(() => {
        scrollTarget(target);
      }, 1000);
    } else {
      scrollTarget(target);
    }
  };

  return (
    <div
      className={
        head ? [classes.Header, classes.NavBar].join(" ") : classes.Header
      }
      id="HeaderId"
    >
      {showLoader && !props.hideLoader ? <LogoLoader /> : null}
      <div
        className={classes.ImageContainer}
        onClick={() => scrollToPage("backgroundId")}
      >
        <img
          src={head ? WO_LOGO_SMALL : WO_LOGO}
          alt="Weboasis logo"
          id="ImageId"
          style={props.logoStyle}
        />
      </div>
      <div
        className={Open ? classes.Show : [classes.Show, classes.Hide].join(" ")}
        id="menuItemsId"
      >
        <div onClick={() => (window.location.href = "/portfolio")}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["2"]}
          </li>
        </div>
        <a onClick={() => scrollToPage("section4")}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["3"]}
          </li>
        </a>
        <a onClick={() => {mobile ?  scrollToPage("section2_2_mobile") : scrollToPage("section2_2_desktop") }}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["1"]}
          </li>
        </a>
        <a onClick={() => scrollToPage("team-section")}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["4"]}
          </li>
        </a>
        <a onClick={() => scrollToPage("EmailSection")}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["6"]}
          </li>
        </a>
        <a onClick={() => scrollToPage("WorkWithUs")}>
          <li onClick={ChangeState} className={classes.LinkText}>
            {Content.header.menuItems["5"]}
          </li>
        </a>
        <div className={classes.HeaderFooter}>
          <div className={classes.HFContainer}>
            <div className={classes.Left}>
              <div className={classes.FLogo}>
                <img src={WO_LOGO} />
              </div>
              <div className={classes.FSocial}>
                <div>
                  <img src={Email} alt="" />
                  {Content.header.footer.social["1"]}
                </div>
                <div>
                  <img src={Instagram} alt="" />
                  {Content.header.footer.social["2"]}
                </div>
              </div>
            </div>
            <div className={classes.Right}>
              <div>{Content.header.footer.copyrights["1"]}</div>
              <div>{Content.header.footer.copyrights["2"]}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.MenuContainer} onClick={ChangeState} id="MenuId" style={props.menuContainerStyle}>
        <button type="button" className={Open ? classes.Change : null}>
          <div className={classes.Bar1}></div>
          <div className={classes.Bar2}></div>
          <div className={classes.Bar3}></div>
        </button>
      </div>
    </div>
  );
};
export default Header;
